<template>
  <div class="app-settings-panel-content">
    <div class="app-settings-list" v-show="curSetting === 'allSetting'">
      <div
        class="app-setting-item bg-color-overlay"
        v-for="(item, index) in appSettingsList"
        :key="index"
        @click.stop="onSetting(item.setting)"
      >
        <div class="app-setting-left">
          <img :src="item.icon" alt="" />
          <span>{{ item.label }}</span>
        </div>
        <div class="app-setting-right">
          <Switcher
            v-if="item.setting === 'chart'"
            :value="marketVisible"
            @switch="setMarketVisible"
          />
          <div class="right-wrap">
            <span>{{ item.labelText }}</span>
            <img :src="enterImg" alt="" />
          </div>
        </div>
      </div>
      <div class="contact-us-button bg-color-overlay" @click="onContactUs">
        <img :src="contactUsImg" alt="" />
        <span>{{ $t('appSetting.contactUs') }}</span>
      </div>
    </div>
    <div class="app-setting-theme-list" v-show="curSetting === 'theme'">
      <AppSettingOtherList
        :list="themeList"
        :settingTitle="$t('appSetting.theme')"
        :theme="theme"
        @getSetting="getSetting"
        @goBack="goBack"
      />
    </div>
    <div class="app-setting-language-list" v-show="curSetting === 'language'">
      <AppSettingOtherList
        :list="languageList"
        :settingTitle="$t('appSetting.language')"
        @getSetting="getSetting"
        @goBack="goBack"
      />
    </div>
    <div class="app-setting-contact-us" v-show="curSetting === 'contact-us'">
      <div class="contact-us-header">
        <img :src="backImg" alt="" @click.stop="goBack" />
        <div class="other-text">{{ $t('home.modal_contact_us.title') }}</div>
      </div>
      <div class="contact-us-content">
        <div class="desc">{{ $t('home.modal_contact_us.text_1') }}</div>
        <ContactUsList />
        <div class="contact-transit-buy">
          <div class="title">{{ $t('home.modal_contact_us.text_2') }}</div>
          <a href="https://openc.zendesk.com/hc/en-us/requests/new" target="_blank" class="button">
            <img :src="contactImg" alt=""/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSettingOtherList from './AppSettingOtherList.vue';
import Switcher from './Switcher.vue';
import ContactUsList from './ContactUsList.vue';

import { getSystemTheme, setMode, changeURLArg } from '../../utils';
import darkMode from '../../mixins/darkMode';
export default {
  components: { Switcher, AppSettingOtherList, ContactUsList },
  data() {
    return {
      theme: 'light',
      curSetting: 'allSetting',
    };
  },
  mixins: [darkMode],
  created() {
    const theme = localStorage.getItem('theme');
    if (theme) {
      this.theme = theme;
    }
  },
  computed: {
    ...mapGetters(['isMobileSize']),
    contactImg() {
      return require(`../../assets/home/contact${this.darkModeImg}.png`);
    },
    contactUsImg() {
      return require(`../../assets/header/contact-us.png`);
    },
    enterImg() {
      return require(`../../assets/header/enter${this.darkModeImg}.png`);
    },
    backImg() {
      return require(`../../assets/header/back${this.darkModeImg}.png`);
    },
    themeImg() {
      if (this.theme === 'light') {
        return require(`../../assets/header/sun${this.darkModeImg}.png`);
      } else if (this.theme === 'dark') {
        return require(`../../assets/header/moon${this.darkModeImg}.png`);
      } else {
        return require(`../../assets/header/auto${this.darkModeImg}.png`);
      }
    },
    appSettingsList() {
      if (this.$route.path === '/' || this.$route.path === '/terms' || this.$route.path === '/privacy-policy') {
        return [
          {
            icon: this.themeImg,
            label: this.$t('appSetting.theme'),
            setting: 'theme',
          },
          {
            icon: require(`../../assets/header/language${this.darkModeImg}.png`),
            label: this.$t('appSetting.language'),
            setting: 'language',
            labelText: this.$t('appSetting.langText'),
          },
          // {
          //   icon: require(`../../assets/header/chart${this.darkModeImg}.png`),
          //   label: this.$t('appSetting.showChart'),
          //   setting: 'chart',
          // },
        ];
      } else {
        return [
          {
            icon: require(`../../assets/header/language${this.darkModeImg}.png`),
            label: this.$t('appSetting.language'),
            setting: 'language',
            labelText: this.$t('appSetting.langText'),
          },
        ];
      }
    },
    themeList() {
      return [
        {
          icon: require(`../../assets/header/sun${this.darkModeImg}.png`),
          iconSelect: require(`../../assets/header/sun-select${this.darkModeImg}.png`),
          name: this.$t('appSetting.light'),
          theme: 'light',
        },
        {
          icon: require(`../../assets/header/moon${this.darkModeImg}.png`),
          iconSelect: require(`../../assets/header/moon-select${this.darkModeImg}.png`),
          name: this.$t('appSetting.dark'),
          theme: 'dark',
        },
        {
          icon: require(`../../assets/header/auto${this.darkModeImg}.png`),
          iconSelect: require(`../../assets/header/auto-select${this.darkModeImg}.png`),
          name: this.$t('appSetting.auto'),
          theme: 'auto',
        },
      ];
    },
    languageList() {
      return [
        {
          name: '简体中文',
          language: 'zh',
        },
        {
          name: 'English',
          language: 'en',
        },
      ];
    },
  },
  watch: {
    isDarkMode: {
      handler(mode) {
        // const theme = window.localStorage.getItem('theme', theme);
        // if (theme && theme === 'auto') {
          
        // }
        // if (mode) {
        //   this.theme = 'dark'
        //   // console.log('mode', mode);
        // } else {
        //   this.theme = 'light'
        // }
      },
      immediate: true
    }
  },
  methods: {
    onContactUs() {
      if (this.isMobileSize) {
        this.curSetting = 'contact-us';
      } else {
        this.$mitt.emit('contact-us', { show: true })
      }
    },
    goBack() {
      this.curSetting = 'allSetting';
    },
    getSetting(data) {
      if (data.theme) {
        this.theme = data.theme;
        this.onSwitchMode(data.theme);
      } else if (data.language) {
        this.switchLang(data);
      }
    },
    onSetting(setting) {
      // console.log('setting', setting);
      if (setting !== 'chart') {
        this.curSetting = setting;
      }
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      localStorage.setItem('lang', this.$i18n.locale);
      changeURLArg({ locale: this.$i18n.locale })
    },
    onSwitchMode(theme) {
      // window.localStorage.setItem('darkmode', !this.isDarkMode ? '1' : '0');
      window.localStorage.setItem('theme', theme);
      let mode = false;
      if (theme === 'auto') {
        const systemTheme = getSystemTheme();
        if (systemTheme === 'dark') {
          mode = true;
        }
      } else if (theme === 'dark') {
        mode = true;
      }
      this.$store.commit('setDarkMode', mode);
      setMode(mode);
      // this.setMode();
    },
    // setMode() {
    //   if (this.isDarkMode) {
    //     document.documentElement.setAttribute('theme', 'dark');
    //   } else {
    //     document.documentElement.removeAttribute('theme');
    //   }
    // },
    setMarketVisible() {
      this.$store.commit('setMarketVisible', !this.marketVisible);
    },
  },
};
</script>

<style lang="less" scoped>
.app-settings-panel-content {
  min-width: 194px;
  .app-settings-list {
    .app-setting-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 8px;
      border-radius: 12px;
      &::before{
        background: var(--bg-1);
        border-radius: 12px;
      }
      cursor: pointer;
      .app-setting-left {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          margin-left: 8px;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: left;
          color: var(--color-5);
          line-height: 18px;
        }
      }
      .app-setting-right {
        .right-wrap {
          display: flex;
          align-items: center;
          span {
            margin-right: 6px;
            font-size: 13px;
            font-family: PingFang SC, PingFang SC-Regular;
            color: var(--color-5);
            line-height: 17px;
          }
          img {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    .contact-us-button {
      margin-top: 8px;
      padding: 11px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2980fe;
      border-radius: 12px;

      &::before {
        background: #216fdf;
        border-radius: 12px;
      }
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 6px;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        line-height: 18px;
      }
    }
  }
  .app-setting-theme-list {
    .theme-header {
      position: relative;
      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      .other-text {
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #333333;
        line-height: 21px;
      }
    }
    .theme-list {
      margin-top: 10px;
    }
  }
  .app-settings-language-list {
  }
}
@media screen and (max-width: 767px) {
  .app-settings-panel-content {
    .app-settings-list {
      .app-setting-item:hover {
        background: none;
      }
      .app-setting-item {
        padding: 22px 8px;
        .app-setting-left {
          span {
            font-size: 15px;
          }
        }
        .app-setting-right {
          .right-wrap {
            img {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
      .contact-us-button {
        margin-top: 20px;
        border-radius: 18px;
        padding: 18px 0;
        span {
          font-size: 15px;
        }
      }
    }
    .app-setting-contact-us {
      .contact-us-header {
        position: relative;
        img {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .other-text {
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: center;
          color: var(--color-5);
          line-height: 21px;
        }
      }
      .contact-us-content {
        margin-top: 22px;
        .desc {
          padding: 0 16px;
          font-size: 13px;
          font-family: Apple SD Gothic Neo, Apple SD Gothic Neo-Regular;
          text-align: center;
          color: #e55036;
        }
        .title {
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Medium;
          color: var(--color);
          font-weight: 500;
        }
        .contact-transit-buy {
          margin-top: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .button {
            margin-top: 16px;
            width: 44px;
            height: 44px;
            border: 1px solid var(--border-1);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
