<template>
  <div class="home-view">
    <Header />
    <HomeContainer />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
import HomeContainer from '../components/Layouts/HomeContainer.vue';
import { setMode } from "../utils";
export default {
  name: 'Home',
  components: { Header, Footer, HomeContainer },
  data() {
    return {};
  },
  beforeRouteLeave(to, from, next) {
    window.localStorage.setItem('theme', 'light');
    this.$store.commit('setDarkMode', false);
    setMode(false)
    next();
  },
};
</script>
<style lang="less">
.home-view {
  background: var(--bg);
}
</style>
