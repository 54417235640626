const en = {
  swap: 'Swap&Bridge',
  markets: 'Markets',
  comingSoon: 'Coming soon',

  header: {
    buyToken: 'Buy Crypto',
    searchTokens: 'Search tokens',
    recentSearches: 'Recent Searches',
    popularTokens: 'Popular Tokens',
    noSearchContent: 'No tokens found.',
    markets: 'Market',
    inscribeTool: 'Inscribe Tool',
  },

  appSetting: {
    theme: 'Theme',
    light: 'Light',
    dark: 'Dark',
    auto: 'Auto',

    language: 'Language',
    langText: 'English',

    showChart: 'Show Chart',
    contactUs: 'Contact Us',
  },

  stake: {
    know: {
      title: 'Stake TPT Get xTPT',
      desc: 'Stake your TPT to get xTPT, which can be used to vote for the ecology improvement proposals. You can swap your xTPT back to TPT anytime you want.',
    },
    join: 'Staked TPT',
    connectWallet: 'Connect Wallet',
    networkNotvalid: 'Please switch to BSC network',
    approve: 'Approve',
    unstake: 'Unstake',
    add: 'Add',
    loginFirst: '-',
    deposit: 'Deposit TPT',
    withdraw: 'Withdraw TPT',
    balance: 'Balance',
    confirm: 'Confirm',
    overbalance: 'Over available balance',
    success: 'Success',
    all: 'All',
    voteTip: 'Use xTPT to vote for the propopals you support!',
    goVote: 'Go to VOTE',
  },

  fee: {
    desc: "Transit Swap launches the aggregate transaction fee discount function, which according to the discount rate corresponding to the sum of the TPT and xTPT quantity held by the user's account address. There are currently 4 discount levels, and in the future, more levels will be split according to the actual situation.",
    feeDiscount: 'Rate discount',
    walletBalance: 'Balance',
    levelRank: 'Level',
    levelBalance: 'Balance (TPT and xTPT)',
    levelDiscountRate: 'Discount Rate',
  },

  donation: {
    know: {
      title: 'Transit Swap Donation Pool Definition:',
      desc: 'As part of the open blockchain world, the Transit Swap project accepts Token donations from individual users, all donations will go into a donation pool, which will be used for purposes including but not limited to Transit Swap project operations and funding for the Transit Swap related ecosystem.',
    },
    tip: {
      title: 'Important note:',
      desc:
        'The Transit Swap project does not offer definitive rewards to donors; donors who wish to return their donations within 72 hours of the donation hash may do so via the official contact form, but requests for return after this time limit will not be processed.\n' +
        'Types of donation tokens we accept: TPT\n',
    },
    donateType: {
      title: 'Donations accepted address',
      desc: 'TPT',
    },
    address: {
      title: 'Donations accepted address:',
    },
    join: 'Donate',
    donateChain: 'Select a chain',
    connectWallet: '连接钱包',
    donateTip: {
      title: 'Caution:',
      desc: 'Transit Swap does not offer definitive rewards to donors; If you regret to donate, please send the transaction hash to official email within 72 hours to require return.',
    },
    warn: 'Please select a chain first',
    transfer: 'Please use TokenPokcet to scan this QR Code to donate TPT',
    copyAddress: 'Copy address',
    copied: 'Copied',
    yourAddress: 'Your address',
    cantDonate: 'The current chain does not support donations',
    confirmDonate: 'Confirm donation',
    donateSuccess: 'Success',
    donateFail: 'Failed',
    overCount: 'Insufficient balance',
    donateAddress: 'Donation address',
    donateCount: 'Donation Amount (TPT)',
    pleaseSwitch1: 'Please switch to ',
    pleaseSwitch2: ' network',
    downloadTP: 'Cannot donate directly, please download TokenPocket',
    connectTronLink: 'Please connect TronLink first',
    waitBlockConfirm: 'Submitted',
    noZero: 'The donation amount cannot be 0',
  },

  home: {
    title_1: 'With the Power of the Cutting-Edge DEX Platform',
    title_2: 'To Trade Like a PRO',
    swap: 'Swap',
    bridge: 'Bridge',
    startTrading: 'Start Trading',
    viewMore: 'View More',
    modal_contact_us: {
      title: 'Contact Us',
      text_1:
        "* Please be aware that Transit will never contact you first, we won't ask you to charge any fee either.",
      text_2: 'Contact Transit Buy',
    },
    markets: {
      title: 'Markets',
      text: 'Get real-time insights to discover hot tokens and trade smarter with the Market &#128200;&#128201; feature.',
      text_1: 'Coming soon',
    },
    onRamp: {
      title: 'OnRamp',
      text: 'Multiple on/off-ramp channels for optimal token buying and selling.',
    },
    swap_bridge: {
      title: 'Swap & Bridge',
      text: 'Uniting top DEXs, optimizing trades with the best prices and unmatched professionalism on multiple chains. Moreover, we offer professional cross-chain bridge services, ensuring seamless asset liquidity.',
    },
    liquidity_options: {
      title: 'Data',
      text_1: 'Better liquidity,',
      text_2: 'better options',
      Chains: 'Chains',
      DEXs: 'DEXs',
      Bridges: 'Bridges',
      Ramps: 'Ramps',
      Tokens: 'Tokens',
    },
    other_features: {
      title: 'Other features',
      NFT: {
        title: 'NFT',
        text_1: 'NFT Marketplace',
        text_2: 'Transit NFT',
        text_3: 'NFT Design Competition',
      },
      NFT: {
        title: 'Governance',
        text_1: 'DAO',
        text_2: 'Donation',
        text_3: 'Fee',
      },
    },
    get_start: {
      text_1: 'Unlock limitless trading possibilities with Transit',
      text_2: 'To trade like a Pro!',
      text_3: 'Get Started',
    },
  },

  terms: {
    title: 'Terms & Conditions',
    text_1_1: 'Last modified: Jan 1st, 2023',
    text_1_2:
      'These Terms of Service (hereinafter referred to as the "<strong>Terms</strong>"), together with any documents and additional terms incorporated by reference (collectively referred to as the "<strong>Terms</strong>"), are entered into by Transit and its affiliates or related companies (collectively referred to as "<strong>Transit</strong>," "<strong>we</strong>," "<strong>our</strong>," or "<strong>us</strong>") and you or the company or other legal entity you represent (collectively referred to as "<strong>you</strong>" or "<strong>your</strong>").',
    text_1_3:
      'Please read these Terms carefully as they govern your use of our website (hereinafter referred to as the "<strong>Website</strong>") and the products, features, content, applications, or services we provide (collectively referred to as the "<strong>Services</strong>"). These Terms describe your rights and obligations as well as our disclaimers and limitations of legal liability. By accessing or using our Website or Services, you accept and agree to be bound by these Terms and comply with them, including the mandatory arbitration clause mentioned in these Terms. If you do not agree to these Terms, you must cease accessing or using our Website or Services.',
    text_1_4:
      'You must be able to enter into legally binding contracts online on behalf of a company or individual. Therefore, you represent that if you agree to these Terms on behalf of a company or other legal entity, you have the legal authority to bind that company or other legal entity to these Terms, and you are at least 18 years old (or of legal age in your jurisdiction, whichever is older) and capable of entering into legally binding contracts online, and have all necessary rights, powers, and authorities to fulfill obligations under these Terms.',
    text_2_1: '1.MODIFICATIONS TO THESE TERMS',
    text_2_2:
      'We reserve the unilateral right to amend these terms at any time. In the event of such changes, we will not provide further notice to you. Unless explicitly stated otherwise in the notice, any modifications shall become effective immediately, and your continued use of the website or our services shall constitute acceptance of these alterations. Should you disagree with the amended terms, it is incumbent upon you to cease usage of our services.',
    text_3_1: '2.SERVICES',
    text_3_2:
      '"Smart Contracts" are publicly available open-source software programs deployed on the blockchain to facilitate peer-to-peer exchange of tokens based on the blockchain.',
    text_3_3:
      'The "blockchain" is a distributed database technology based on a decentralized network structure, maintained and updated by multiple nodes, forming a chain-like data structure. In the blockchain, data is stored in the form of blocks and secured and immutable through cryptographic algorithms and consensus mechanisms. Each block contains the hash value of the previous block and transaction data, forming an irreversible chain. The blockchain features decentralization, transparency, and immutability. Blockchain networks include but are not limited to Bitcoin, Ethereum, TRON, Binance Smart Chain, Solana.',
    text_3_4:
      'Transit has developed software through which users and developers can interact with Transit smart contracts. The primary purpose of the Website is to enable users to access and utilize Transit smart contracts and Transit software products and provide resources and information, including but not limited to developer documentation, product examples, and other related services (all of which fall within the definition of "Services").',
    text_3_5: `The "Transit Network" consists of all users, companies, applications, and any other devices, services, or personnel that interact in any way with Transit smart contracts on the blockchain, including Transit's services. The term "Transit Network" is to be construed as broadly as possible to include any content that may reasonably be considered part of the network.`,
    text_3_6:
      'Some services provided by us or other participants in the Transit Network may require payment or involve the use of underlying blockchain or other decentralized or permissioned infrastructure ("Distributed Ledger Technology"), which may require you to pay fees, such as "gas" fees on the Ethereum network, for computational resources required to execute transactions on specific Distributed Ledger Technology (such payments and fees collectively referred to as "<strong>Fees</strong>").',
    text_3_7:
      'You acknowledge and agree that Transit has no control over any Distributed Ledger Technology transactions, any payment methods for Fees (if applicable), or any actual Fee payments (if applicable). Therefore, before initiating such transactions, you must ensure that you have a sufficient balance of applicable tokens on your Distributed Ledger Technology-compatible wallet address ("Distributed Ledger Technology Address") to complete any transactions on the Transit Network or Distributed Ledger Technology.',
    text_4_1: '3.TERMS OF USE',
    text_4_2:
      'As a condition of using or accessing the Services or the Website, you must:',
    text_4_3:
      '· Use the Services and Website for lawful purposes and comply with these Terms;',
    text_4_4:
      '· Ensure that all information you provide on the Website is current, complete, accurate, and true;',
    text_4_5: '· Comply with all applicable laws and regulations;',
    text_4_6: '· Keep your account private key secure;',
    text_4_7: '· Provide true identity information when requested by us;',
    text_4_8:
      '· Not engage in any fraudulent or unlawful activities while using the Services or Website.',
    text_4_9:
      'You are fully responsible for safeguarding your account and private key. We are not responsible for any loss, theft, disclosure, misuse, or alteration of your account or private key, nor are we liable for any resulting losses.',
    text_5_1: '4.RESTRICTED USE',
    text_5_2:
      'Unless permitted by law or expressly agreed upon by us, you may not:',
    text_5_3: `· Use the Services or Website for any illegal purpose or activity;`,
    text_5_4:
      '· Request, collect, or store personal information or data of any other users;',
    text_5_5:
      '· Unauthorized modification, tampering, reverse engineering, or destruction of any part of the Services or Website;',
    text_5_6:
      '· Access the Services or Website by means other than those provided by us without our prior written consent;',
    text_5_7:
      '· Distribute spam, links, malware, or any other unauthorized content through the Services or Website;',
    text_5_8:
      '· Attempt to interfere with, disrupt, or circumvent any security features of the Services or Website;',
    text_5_9:
      '· Exploit any vulnerabilities or errors in the Services or Website for any unauthorized access or operation;',
    text_5_10:
      '· Engage in any activity through the Services or Website that may harm us or any other users, including but not limited to spreading malicious code or conducting denial-of-service attacks;',
    text_5_11:
      '· Use any Transit trademarks, logos, names, or other branding materials for any commercial purposes or through the Services or Website without our written permission;',
    text_5_12: `·Use the Services or Website in any way that may harm, prohibit, or restrict other users' use of the Services or Website;`,
    text_5_13: 'Violate applicable Terms of Use or our Privacy Policy.',
    text_6_1: '5.RESERVATION OF RIGHTS',
    text_6_2:
      'We reserve the right, at our sole discretion, to terminate, suspend, or restrict your access to or use of the Services or Website for any reason. We also reserve the right, at our sole discretion, to modify, interrupt, or discontinue any part of the Services or Website, whether temporarily or permanently, without prior notice to you. You agree that we shall not be liable for exercising these rights.',
    text_7_1: '6.OWNERSHIP',
    text_7_2:
      'The Website, Services, and their original content, features, and functionality (including but not limited to all software, text, graphics, images, audio, video, photographs, charts, icons, code, documents, and other materials) are our property or the property of our licensors and are protected by intellectual property laws and other laws. Unless otherwise specified in these Terms, you may not copy, modify, distribute, sell, lease, rent, transfer, sublicense, or otherwise use the Website, Services, or any part thereof, nor create derivative works related to the Services.',
    text_8_1: '7.THIRD-PARTY LINKS',
    text_8_2: `The Services or Website may contain links to third-party websites or resources, which are provided for users' convenience only. We make no guarantees regarding the accuracy, availability, or endorsement of the content, products, or services of these third-party websites. Users access any third-party websites or resources at their own risk and should comply with any additional terms and conditions applicable to such websites or resources.`,
    text_9_1: '8.DISCLAIMER',
    text_9_2:
      'Unless otherwise agreed, our Website and Services are provided "as is." We make no representations or warranties regarding the accuracy, completeness, timeliness, reliability, availability, suitability, security, or error-free nature of the Website, Services, or their content.',
    text_10_1: '9.RELEASE',
    text_10_2:
      'To the maximum extent permitted by applicable law, we shall not be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising from or in connection with the use or inability to use the Website or Services, including but not limited to loss of profits, loss of data, business interruption, or any other commercial damages, even if we have been advised of the possibility of such damages. Our total liability shall not exceed the fees you paid for using the Website or Services (if any), or 100 Singapore dollars (whichever is higher).',
    text_11_1: '10.DISPUTE RESOLUTION AND ARBITRATION',
    text_11_2:
      'These Terms shall be governed by Singapore law and shall be interpreted and enforced in accordance with Singapore law, without regard to conflict of laws principles. Any disputes arising from or relating to these Terms, Services, or Website shall first be resolved through good-faith negotiations to the maximum extent possible. If no resolution is reached within 30 days, either party may submit the dispute to the courts of Singapore, even if there is any objection or agreement to arbitration in these Terms.',
    text_12_1: '11.OTHER PROVISIONS',
    text_12_2:
      'If any part of these Terms is found to be unenforceable, that part shall be interpreted in accordance with applicable law, and the rest shall remain in full force and effect. Our failure to exercise any right or provision of these Terms shall not constitute a waiver of such right or provision.',
    text_13_1: 'These Terms and Conditions are effective as of July 6, 2021.',
    text_13_2: '©2024 Transit.Finance. All rights reserved.',
  },

  privacy_policy: {
    title: 'Privacy Policy',
    text_1_1: 'Last Modified: Feb 2nd, 2024',
    text_1_2:
      'Transit.Finance (hereinafter referred to as "<strong>we</strong>", "<strong>our company</strong>" or "<strong>the Company</strong>") is committed to protecting your privacy. This Privacy Policy (hereinafter referred to as the "Policy") is intended to explain to you how we collect, use, and share your personal data, as well as your related rights. Please read the following carefully to understand our privacy practices.',
    text_2_1: '1. DATA COLLECTION AND USE',
    text_2_2:
      'Our services aim to provide decentralized trading and associated services. When you use our services, we may collect the following types of information:',
    text_2_3:
      '- Publicly available blockchain data: When you use our services to connect your non-custodial blockchain wallet, we may collect your publicly available blockchain address to understand your transaction activities and screen for any improper behavior. Please note that these blockchain addresses are publicly available data, not created or assigned by any centralized institution, and cannot identify individual identities.',
    text_2_4:
      '- Information from local storage (localStorage) and other tracking technologies: We and our third-party service providers may use local storage, device IDs, cookies, web beacons, and other tracking technologies to provide and personalize our services and their functionalities. We may collect information such as browser type, referrer/exit pages, operating system, device or browser language, etc., to understand user preferences and improve service experiences.',
    text_2_5:
      '- Information from other sources: To comply with legal obligations, prevent fraud, or other illegal activities, we may receive information about your wallet address or transactions conducted through the service from our service providers.',
    text_2_6:
      '- Information you specifically provide to us: If you provide us with information (such as your email address), we may use that information for the purposes described when you provided it to us.',
    text_2_7:
      'We only collect and use this information to provide, maintain, and improve our services and conduct compliance reviews as required by law.',
    text_3_1: '2. DATA SHARING',
    text_3_2:
      'We may share or disclose your personal data with the following parties:',
    text_3_3:
      '- Service providers: To help us provide, deliver, and improve services, we may share your information with service providers and suppliers. For example, we may share your wallet address with blockchain analytics providers to detect, prevent, and mitigate financial crimes and other illegal activities.',
    text_3_4:
      '- Legal obligations: In litigation, regulatory proceedings, compliance measures, or other legal proceedings, we may share your data. We may also disclose information as required by law to prevent harm to users, the company, or others, and enforce our agreements and policies.',
    text_3_5:
      '- Security and protection: To protect our users, company, and ecosystem, we may share data to prevent, investigate, and stop fraud, unauthorized or illegal activities, and address potential security issues.',
    text_3_6:
      '- Business changes: In mergers, acquisitions, bankruptcies, dissolutions, reorganizations, asset or stock sales, or other business transactions, we may transfer or share data with another entity.',
    text_3_7:
      '- Your consent: We will not share your information with any third party without your consent.',
    text_3_8: 'We will not use your information for any marketing purposes.',
    text_4_1: '3. THIRD-PARTY COOKIES',
    text_4_2:
      'We use services provided by Google and other third parties that use tracking technologies (such as cookies) to collect information about your use of the service and our interactions with you. You can refuse cookies through your browser settings or use specific browser plugins to manage and delete cookies.',
    text_5_1: '4. THIRD-PARTY LINKS AND SITES',
    text_5_2:
      'Our service may contain links to other third-party websites or services. Please note that when you interact with these third parties, they may independently collect information about you. You can review the privacy policies of these third parties for more information. The privacy policies of third-party service providers are not related to our privacy policy.',
    text_6_1: '5. SECURITY',
    text_6_2:
      'We take reasonable administrative, physical, and technical security measures to protect data from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, internet transmission is not entirely secure, and we cannot guarantee the security of your information. You are responsible for protecting all activities on our service, including the security of your blockchain network addresses, cryptocurrency wallets, and their encryption keys.',
  },

  footer: {
    Features: {
      title: 'Features',
      swap: 'Swap&Bridge',
      bridge: 'Bridge',
      market: 'Market',
      buyCrypto: 'Buy Crypto',
      explorer: 'Explorer',
    },
    NFT: {
      title: 'NFT',
      design: 'Design',
      transitNFT: 'Transit NFT',
      marketplace: 'Marketplace',
    },
    Governance: {
      title: 'Governance',
      dao: 'DAO',
      donation: 'Donation',
      fee: 'Fee',
    },
    Community: {
      title: 'Community',
      developers: 'Developers',
      forum: 'Forum',
      twitter: 'Twitter',
      downloadTP: 'Download TP',
    },
    Help: {
      title: 'Get Help',
      contactUs: 'Contact Us',
      helpCenter: 'Help Center',
    },
    Links: {
      title: 'Links',
      TP: 'TokenPocket',
      Trading_View: 'Trading View',
      Terms: 'Terms & Conditions',
      Privacy_Policy: 'Privacy Policy',
      bugBounty: 'Bug Bounty',
    },
  },
};

export default en;
