const zh = {
  swap: '闪兑&跨链',
  markets: '市场',
  comingSoon: '即将推出',

  header: {
    buyToken: '购买代币',
    searchTokens: '搜索代币',
    recentSearches: '最近搜索',
    popularTokens: '热门代币',
    noSearchContent: '暂无搜索内容',
    markets: '市场',
    inscribeTool: '铭刻工具',
  },

  appSetting: {
    theme: '主题',
    light: '浅色主题',
    dark: '深色主题',
    auto: '跟随系统',

    language: '语言',
    langText: '中文',

    showChart: '市场行情',
    contactUs: '联系我们',
  },

  stake: {
    know: {
      title: '抵押 TPT 获得 xTPT',
      desc: '抵押 TPT 获得 xTPT, xTPT 可以用于生态提案的投票。 你可以随时兑换TPT 与 xTPT，目前仅支持BSC链的TPT。 更多链陆续上线中...',
    },
    join: '已抵押 TPT',
    connectWallet: '连接钱包',
    networkNotvalid: '请切换到BSC网络',
    approve: '授权',
    unstake: '解押',
    add: '增加',
    loginFirst: '请先连接钱包',
    deposit: '抵押 TPT',
    withdraw: '解押 TPT',
    balance: '余额',
    confirm: '确认',
    overbalance: '超过可用余额',
    success: '操作成功',
    all: '全部',
    voteTip: '使用 xTPT 来投票你支持的提案!',
    goVote: '去投票',
  },

  fee: {
    desc: 'Transit Swap上线聚合交易手续费折扣功能，根据用户账户地址持有的TPT与xTPT数量的总和对应的折扣率进行手续费折扣。当前折扣等级分为4级，将来根据实际情况，进行更多层级拆分。',
    feeDiscount: '费率折扣',
    walletBalance: '钱包余额',
    levelRank: '等级',
    levelBalance: '余额（TPT及xTPT)',
    levelDiscountRate: '折扣率',
  },

  donation: {
    know: {
      title: 'Transit Swap捐赠池定义：',
      desc: '作为开放的区块链世界的一份子，Transit Swap项目接受来自个人用户的Token捐赠，所有捐赠将进入捐赠池，池内资金将用于包括但不限于Transit Swap项目运营与对Transit Swap相关生态的资助。',
    },
    tip: {
      title: '重要提示：',
      desc: 'Transit Swap项目不会对捐赠者有确定性的回报与奖励；在捐赠哈希72小时内，希望退回捐赠的捐赠者可通过官方联系方式提出原路返还要求，超过这一时限的返还要求将不被处理。',
    },
    donateType: {
      title: '我们接受的捐赠代币种类：',
      desc: 'TPT',
    },
    address: {
      title: '接受捐赠的地址：',
    },
    join: '参与捐赠',
    donateChain: '选择捐赠的链',
    connectWallet: '连接钱包',
    donateTip: {
      title: '注：',
      desc: '在捐赠哈希72小时内，希望退回捐赠的捐赠者可通过官方联系方式提出原路返还要求，超过这一时限的返还要求将不被处理。',
    },
    warn: '请先选择捐赠的链',
    transfer: '请使用TokenPocket扫描该二维码进行TPT捐赠',
    copyAddress: '复制地址',
    copied: '复制成功',
    yourAddress: '您的地址',
    cantDonate: '当前链不支持捐赠',
    confirmDonate: '确定捐赠',
    donateSuccess: '捐赠成功',
    donateFail: '捐赠失败',
    overCount: '余额不足',
    donateAddress: '捐赠地址',
    donateCount: '捐赠数量 (TPT)',
    pleaseSwitch1: '请切换到',
    pleaseSwitch2: '网络',
    downloadTP: '无法直接捐赠，请下载TokenPocket钱包',
    connectTronLink: '请先连接TronLink钱包',
    waitBlockConfirm: '提交成功',
    noZero: '捐赠数量不能为0',
  },

  home: {
    title_1: '凭借强大且先进的DEX平台',
    title_2: '让你更专业地交易',
    swap: '闪兑',
    bridge: '跨链',
    startTrading: '开启交易',
    viewMore: '了解更多',
    modal_contact_us: {
      title: '联系方式',
      text_1:
        '* 请注意，Transit团队绝不会主动联系您，我们也不会要求您支付任何费用。',
      text_2: '联系 Transit Buy',
    },
    markets: {
      title: '市场',
      text: '获取实时数据，发现热门代币，并通过市场 &#128200;&#128201; 功能做出更智慧的交易决策。',
      text_1: '即将推出',
    },
    onRamp: {
      title: '购买',
      text: '通过多个出入金渠道，让您选择最优的价格实现加密资产的购买和出售。',
    },
    swap_bridge: {
      title: '闪兑&跨链',
      text: '汇聚头部DEXs，在多个链上以最佳价格和顶尖的专业水平进行交易优化。此外，我们提供专业的跨链桥服务，确保您的资产流动性无缝连接。',
    },
    liquidity_options: {
      title: '数据',
      text_1: '更好的流动性,',
      text_2: '更好的选择',
      Chains: '公链',
      DEXs: 'DEX',
      Bridges: '跨链桥',
      Ramps: '法币渠道',
      Tokens: '代币',
    },
    other_features: {
      title: '其他',
      NFT: {
        title: 'NFT',
        text_1: 'NFT市场',
        text_2: 'Transit NFT',
        text_3: 'NFT设计大赛',
      },
      NFT: {
        title: '治理',
        text_1: 'DAO',
        text_2: '捐赠',
        text_3: '费率',
      },
    },
    get_start: {
      text_1: '通过 Transit 解锁交易的无限可能',
      text_2: '让你的交易更专业!',
      text_3: '立即开启',
    },
  },

  terms: {
    title: '服务条款',
    text_1_1: '最后修改日期：2023.01.01',
    text_1_2:
      '本服务条款（以下简称“<strong>条款</strong>”），连同任何文件和额外条款，通过引用纳入其中（统称为“<strong>本条款</strong>”），由Transit及其附属公司或关联公司（统称为“<strong>Transit</strong>”、“<strong>我们</strong>”、“<strong>我们的</strong>”）与您或您代表的公司或其他法律实体（统称为“<strong>您</strong>”或“<strong>您的</strong>”）之间签订。',
    text_1_3:
      '请仔细阅读这些条款，因为它们规范了您对我们网站（以下简称“<strong>网站</strong>”）和我们提供的产品、功能、内容、应用程序或服务（与网站一起，统称为“<strong>服务</strong>”）的使用。这些条款描述了您的权利和义务以及我们的免责声明和法律责任限制。通过访问或使用我们的网站或服务，您接受并同意受这些条款的约束，并遵守这些条款，包括条款中提及的强制仲裁条款。如果您不同意这些条款，则必须停止访问或使用我们的网站或服务。',
    text_1_4:
      '您必须能够在公司或个人的名义在线形成具有法律约束力的合同。因此，您声明，如果您代表公司或其他法律实体同意这些条款，您具有将该公司或其他法律实体与这些条款约束的法律权力，并且您至少年满18岁（或者在您居住的地方达到法定年龄，以较年长者为准），可以在线形成具有法律约束力的合同，并且具有在这些条款下履行义务的全部、完整、权利、权力和权限。',
    text_2_1: '1.修改这些条款',
    text_2_2:
      '我们保留随时单方面修改这些条款的权利。在进行修改时，我们将不会另行通知您。除非通知中另有说明，任何修改都将立即生效，您继续使用我们的网站或服务将被视为您接受了这些修改。如果您不同意修改后的条款，则必须停止使用我们的服务。',
    text_3_1: '2.服务',
    text_3_2:
      '“智能合约”是部署在区块链上的公开可用的开源软件程序，用于促进基于区块链的代币的点对点交换。',
    text_3_3:
      '"区块链"是一种分布式数据库技术，它基于去中心化的网络结构，由多个节点共同维护和更新数据的链式数据结构。在区块链中，数据以区块的形式存储，并通过加密算法和共识机制确保数据的安全性和不可篡改性。每个区块包含了前一个区块的哈希值以及交易数据，形成了一个不可逆的链条。区块链有去中心化、透明化、不可篡改等特点。区块链网络不阔但不限于比特币、以太坊、波场TRON、币安智能链、Solana。',
    text_3_4:
      'Transit 开发了一种软件，通过该软件，用户和开发人员可以与 Transit 智能合约进行交互。网站的主要目的是让用户可以访问和利用 Transit 智能合约和 Transit 软件产品，并提供资源和信息，包括但不限于开发人员文档、产品示例和其他相关服务（所有这些都属于“服务”定义范围内）。',
    text_3_5:
      '“Transit 网络”由所有直接或间接与区块链上的 Transit 智能合约进行任何方式互动的用户、公司、应用程序和任何其他设备、服务或人员组成，包括 Transit 的服务。应尽可能广泛地解释“Transit 网络”一词，以包括任何可能被合理视为网络一部分的内容。',
    text_3_6:
      '我们或 Transit 网络中其他参与者提供的一些服务需要付费或涉及使用基础区块链或其他去中心化或权限基础设施（“分布式账本技术”），这可能需要您支付费用，例如以太坊网络上的“燃气”费用，用于执行特定分布式账本技术上的交易所需的计算资源（此类付款和费用统称为“<strong>费用</strong>”）。',
    text_3_7:
      '您承认并同意 Transit 对任何分布式账本技术交易、任何费用的支付方式（如果适用）或任何实际费用支付（如果适用）没有任何控制权。因此，在启动此类交易之前，您必须确保在您的分布式账本技术兼容钱包地址（“分布式账本技术地址”）上存有足够的适用分布式账本技术网络代币的余额，以完成 Transit 网络或分布式账本技术上的任何交易。',
    text_4_1: '3.使用条款',
    text_4_2: '作为使用或访问服务或网站的条件，您必须：',
    text_4_3: '· 仅出于合法目的并遵守这些条款使用服务和网站；',
    text_4_4: '· 确保您在网站上提供的所有信息是当前、完整、准确和真实的；',
    text_4_5: '· 遵守所有适用的法律和法规；',
    text_4_6: '· 保持您的帐户私钥安全；',
    text_4_7: '· 在我们要求时提供真实身份信息；',
    text_4_8: '· 不要在使用服务或网站时从事任何不端或违法行为。',
    text_4_9:
      '您对您的帐户和私钥的保管负有全部责任。我们无法对您的帐户或私钥的丢失、窃取、泄露、滥用或篡改负责，也不对此造成的任何损失负责。',
    text_5_1: '4.受限制的使用',
    text_5_2: '除非法律允许或我们明确同意，否则您不得：',
    text_5_3: '· 使用服务或网站进行任何非法用途或与任何非法活动有关；',
    text_5_4: '· 请求、收集或存储任何其他用户的个人信息或数据；',
    text_5_5: '· 擅自修改、篡改、反向工程或破坏服务或网站的任何部分；',
    text_5_6:
      '· 在未经我们事先书面同意的情况下，通过爬虫、机器人或其他自动或手动方式访问服务或网站；',
    text_5_7:
      '· 通过服务或网站传播垃圾邮件、链接、恶意软件或任何其他未经授权的内容；',
    text_5_8: '· 试图干扰、破坏或绕过服务或网站的任何安全功能；',
    text_5_9: '· 利用服务或网站的任何漏洞或错误进行任何未经授权的访问或操作；',
    text_5_10:
      '· 利用服务或网站进行任何可能损害我们或任何其他用户的活动，包括但不限于传播恶意代码或创建负载攻击；',
    text_5_11:
      '· 未经我们的书面许可，出于任何商业目的或通过服务或网站使用任何 Transit 的商标、标志、名称或其他品牌素材；',
    text_5_12:
      '· 将服务或网站用于任何可能损害、禁止或限制其他用户使用服务或网站的方式；',
    text_5_13: '· 违反适用的服务或网站使用条款或我们的隐私政策。',
    text_6_1: '5.权利保留',
    text_6_2:
      '我们保留自行决定的权利，随时以任何理由终止、暂停或限制您访问或使用服务或网站，或限制您对您的帐户的访问。我们还保留自行决定的权利，随时修改、中断或停止服务或网站的任何部分，无论是暂时还是永久性的，而无需事先通知您。您同意我们对于行使这些权利不承担任何责任。',
    text_7_1: '6.所有权',
    text_7_2:
      '网站、服务和其原始内容、功能和功能（包括但不限于所有软件、文本、图形、图片、音频、视频、照片、图表、图标、编程代码、文档和其他素材）是我们的财产或我们的许可方的财产，并受知识产权法律和其他法律的保护。除非本条款另有明确规定，否则您不得复制、修改、分发、出售、租赁、租赁、转让、授予许可、或以其他方式使用网站、服务或其任何部分，也不得创建与服务相关的派生作品。',
    text_8_1: '7.第三方链接',
    text_8_2:
      '服务或网站可能包含链接到第三方网站或资源的链接，这些链接仅为用户提供方便。我们对于这些第三方网站或资源的内容、产品、服务的准确性、可用性不作任何担保，并且不对任何第三方网站的内容、产品、服务或其他材料进行背书。用户访问任何第三方网站或资源需自行承担风险，并应遵守适用于这些网站或资源的任何额外条款和条件。',
    text_9_1: '8.免责声明',
    text_9_2:
      '除非另有明确约定，否则我们的网站和服务是“按现状”提供的。我们不对网站、服务或其内容的准确性、完整性、及时性、可靠性、可用性、适用性、安全性或无错误性做出任何陈述或担保。',
    text_10_1: '9.限制责任',
    text_10_2:
      '在适用法律允许的最大范围内，我们对因使用或无法使用网站或服务而导致的任何直接、间接、惩罚性、附带、特殊或后果性损害不承担责任，包括但不限于利润损失、数据丢失、业务中断或任何其他商业损失，即使我们已被告知可能发生这些损失。我们的总责任不得超过您为使用网站或服务支付的费用（如果有），或者100新元（以较高者为准）。',
    text_11_1: '10.争议解决',
    text_11_2:
      '本条款受新加坡法律管辖，并应根据新加坡法律解释和执行，但不考虑冲突法规。任何因或与本条款、服务或网站而产生的争议应首先尽最大努力通过友好协商解决。如果在30天内未能达成解决方案，则任何一方均有权将争议提交至新加坡法院，即使本条款中有任何异议或约定仲裁。',
    text_12_1: '11.其他规定',
    text_12_2:
      '如果本条款的任何部分被认为是不可执行的，该部分应根据适用法律进行解释，其余部分仍应具有完整的法律效力。我们未能行使本条款规定的任何权利或规定的任何权利或补救措施，并不构成对这些权利或补救措施的放弃。',
    text_13_1: '这些条款及条件于2021年7月6日生效。',
    text_13_2: '©2024 Transit.Finance. All rights reserved.',
  },
  privacy_policy: {
    title: '隐私政策',
    text_1_1: '最后修改日期：2024年2月2日',
    text_1_2:
      'Transit.Finance（以下简称“<strong>我们</strong>”、“<strong>我们的公司</strong>”或“<strong>公司</strong>”）致力于保护您的隐私。本隐私政策（以下简称“政策”）旨在向您解释我们如何收集、使用和共享您的个人数据以及您的相关权利。请仔细阅读以下内容，以了解我们的隐私做法。',
    text_2_1: '1. 数据收集与使用',
    text_2_2:
      '我们的服务旨在提供去中心化交易及其衍生服务。在您使用我们的服务时，我们可能会收集以下类型的信息：',
    text_2_3:
      '- 公开可用的区块链数据：当您使用我们的服务连接您的非托管区块链钱包时，我们可能会收集您的公开可用的区块链地址，以便了解您的交易活动并筛查是否存在任何不当行为。请注意，这些区块链地址是公开可用的数据，不会被任何中心化机构创建或分配，并且本身无法识别个人身份。',
    text_2_4:
      '- 本地存储（localStorage）和其他跟踪技术的信息：我们及我们的第三方服务提供商可能会使用本地存储、设备ID、Cookies、网络信标等跟踪技术，以提供和个性化我们的服务及其功能。我们可能会收集浏览器类型、引荐/退出页面、操作系统、设备或浏览器语言等信息，以了解用户的偏好和改善服务体验。',
    text_2_5:
      '- 来自其他来源的信息：为了遵守法律义务、防止欺诈或其他非法活动，我们可能会从我们的服务提供商处接收关于您的钱包地址或通过服务进行的交易的信息。',
    text_2_6:
      '- 您专门向我们提供的信息：如果您向我们提供信息（如您的电子邮件地址），我们可能会将该信息用于您提供给我们时描述的目的。',
    text_2_7:
      '我们仅会收集并使用这些信息来提供、维护和改进我们的服务，并根据法律要求进行合规性审查。',
    text_3_1: '2. 数据共享',
    text_3_2: '我们可能会与以下各方分享或披露您的个人数据：',
    text_3_3:
      '- 服务提供商：为了帮助我们提供、交付和改进服务，我们可能会与服务提供商和供应商分享您的信息。例如，我们可能会与区块链分析提供商分享您的钱包地址，以检测、预防和减轻金融犯罪等非法活动。',
    text_3_4:
      '- 法律义务：在诉讼、监管程序、合规措施或其他法律程序中，我们可能会分享您的数据。我们还可能会在法律要求时披露信息，以防止对用户、公司或其他人的损害，并执行我们的协议和政策。',
    text_3_5:
      '- 安全与保护：为了保护我们的用户、公司和生态系统，我们可能会分享数据以防范、调查和制止欺诈、未经授权或非法活动，以及解决潜在的安全问题。',
    text_3_6:
      '- 业务变更：在合并、收购、破产、解散、重组、资产或股票出售或其他业务交易中，我们可能会转让或共享数据给另一实体。',
    text_3_7: '- 您的同意：除非您同意，否则我们不会与任何第三方分享您的信息。',
    text_3_8: '我们不会将您的信息用于任何营销目的。',
    text_4_1: '3. 第三方Cookie',
    text_4_2:
      '我们使用由Google和其他第三方提供的服务，这些服务使用跟踪技术（如Cookies）收集关于您使用服务和我们与您的互动的信息。您可以通过浏览器设置拒绝Cookie，或使用特定的浏览器插件来管理和删除Cookie。',
    text_5_1: '4. 第三方链接与站点',
    text_5_2:
      '我们的服务可能包含链接到其他第三方网站或服务的技术。请注意，当您与这些第三方进行互动时，他们可能会独立收集关于您的信息。您可以查阅这些第三方的隐私政策以了解更多信息。第三方服务提供商的隐私政策与与我们的隐私政策无关。',
    text_6_1: '5. 安全性',
    text_6_2:
      '我们采取合理的行政、物理和技术安全措施来保护数据免受丢失、盗窃、滥用、未经授权的访问、披露、更改和销毁。然而，因特网传输并非完全安全，我们无法保证您的信息的安全。您有责任保护好您在我们服务上的所有活动，包括您的区块链网络地址、加密货币钱包及其加密密钥的安全性。',
  },

  footer: {
    Features: {
      title: '功能',
      swap: '闪兑&跨链',
      bridge: '跨链桥',
      market: '市场行情',
      buyCrypto: '购买',
      explorer: '浏览器',
    },
    NFT: {
      title: 'NFT',
      design: '设计大赛',
      transitNFT: 'Transit NFT',
      marketplace: 'NFT市场',
    },
    Governance: {
      title: '治理',
      dao: 'DAO',
      donation: '捐赠',
      fee: '费率',
    },
    Community: {
      title: '社区',
      developers: '开发者',
      forum: '论坛',
      twitter: 'Twitter',
      downloadTP: '下载 TP',
    },
    Help: {
      title: '帮助',
      contactUs: '联系我们',
      helpCenter: '帮助中心',
    },
    Links: {
      title: '链接',
      TP: 'TokenPocket',
      Trading_View: 'Trading View',
      Terms: '服务条款',
      Privacy_Policy: '隐私政策',
      bugBounty: '漏洞赏金',
    },
  },
};

export default zh;
